html {
  font-size: 12px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  height: 100vh;
  max-height: 700px;
  background: radial-gradient(var(--background-inner), var(--background-mid), var(--background-outer));

  /* original pad colors */
  --wisteria: #B59ECDff;
  --celestial-blue: #338ECCff;
  --melon: #FAB8B5ff;
  --maya-blue: #8AC5F4ff;

  color: ghostwhite;
  --panel-color-1: #d46174;
  --panel-color-2: #e38471;
  --off-color-1: #ecdda6;
  --off-color-2: #36454f;
  --off-color-3: whitesmoke;
  --inactive-color: grey;
  --pad-kick: var(--sampler-bottom);
  --pad-snare: var(--sampler-bottom);
  --pad-hat: var(--sampler-bottom);
  --pad-1: var(--wisteria);
  --pad-2: var(--wisteria); 
  --pad-3: var(--wisteria);
  --pad-4: var(--wisteria);
  --background-inner: #e66465;
  --background-mid: #cc5577;
  --background-outer: #9198e5;
  /* aliases */
  --sampler-bottom: #556d7c;
  --control-bar: var(--off-color-1);
  --btn-red: var(--panel-color-1);
  /* layout */
  --track-label-width: 60px;
}

button {
  font-family: inherit;
  /* For all browsers */
  font-size: 100%;
  /* For all browsers */
  line-height: 1.15;
  /* For all browsers */
  margin: 0;
  /* Firefox and Safari have margin */
  overflow: visible;
  /* Edge hides overflow */
  text-transform: none;
  /* Firefox inherits text-transform */
  -webkit-appearance: button;
  /* Safari otherwise prevents some styles */

  /* styling */
  border: 0;
  border-radius: 0.25rem;
  background: var(--panel-color-2);
  color: white;
  font-family: -system-ui, sans-serif;
  font-size: 1rem;
  line-height: 1.2;
  white-space: nowrap;
  text-decoration: none;
  padding: 2px 5px 0px;
  margin: 0px 0.2rem;
  cursor: pointer;
}

button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring {
  outline: 1px dotted ButtonText;
}

button:disabled {
  background: var(--inactive-color);
  color: lightgrey;
  cursor: default;
}

button:hover{background-color: var(--panel-color-1);}


input[type="range"] {
  -webkit-appearance: none;
  margin-right: 15px;
  height: 7px;
  background: var(--sampler-bottom);
  border-radius: 5px;
  cursor: pointer;
}

input[type="range"]::-webkit-slider-runnable-track,
input[type="range"]::-moz-range-thumb {
  -webkit-appearance: none;
  height: 4px;
  width: 4px;
  border-radius: 50%;
  background: black;
  border: 1px solid black
}

@keyframes tracker {
  0% { left: 0 }
  100% { left: 100% }
}